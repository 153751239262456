<template>
  <div class="account container-fluid min-vh-100">
    <vue-headful
      title="Install-HC - Mon compte"
      description="Espace personnel du site Install-HC"
    />
    <div>
      <nav role="navigation">
        <div id=header class="float-right">
          <InstallHC :key="componentKey" @notlogged="notlogged"/>
        </div> 
        <Menu :key="componentKey"/>
      </nav>
    </div>
    <div class="myAccount">
      <h1>Mon compte</h1>            
      <div v-if="login">
        <div class="whitebg actionBox shadow">
          <p>
            Actions possibles
          </p>
          <ul>
            <li><a @click="deconnexion" href="#">Me déconnecter</a></li>
            <li><router-link to="/share">Poster ou modifier mon Installation HC</router-link></li>
          </ul>
          <Habilitations />
        </div>
        <InstallationsList :fav="login"/>
      </div>
      <div v-else>
        <p>
          Vous n'êtes pas connecté !
        </p>
      </div>
    </div>    
  </div>  
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import Menu from '@/components/Menu.vue'
import InstallHC from '@/components/InstallHC.vue'
import InstallationsList from '@/components/InstallationsList.vue'
import Habilitations from '@/components/Habilitations.vue'
export default { 
  name: 'account',
  components: {
    Menu,
    InstallHC,
    InstallationsList,
    Habilitations    
  },
  data() {         
    return { 
      login: null,
      componentKey: 0    
    }    
  },  
  methods:{  
    forceRerender: function() {
      this.componentKey += 1             
      this.$router.push('/')
      location.reload(true)
    },
    notlogged: function() {
      this.forceRerender()
    }, 
    deconnexion: function() {
      axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/form/unlink').then(response => {this.json = response.data.unlink;
        let vm = this;        
        vm.login = null
        localStorage.removeItem('login')
        localStorage.removeItem('admin')
        localStorage.removeItem('installOwner')
        //this.forceRerender()
        setTimeout(() => {
              this.forceRerender()
            }, 1500); 
      })      
    } 
  },
  mounted() {
    if (localStorage.login) {
      this.login = localStorage.login
    } else {
      setTimeout(() => {
              this.forceRerender()
            }, 1500);
    }
  }   
}

</script>

<style scoped>
.debug {
  display: none;
}

.account {
  margin:0 auto;  
  padding:0;
}

h1 {
  font-size:1.05em;
  font-weight:bold;
  text-align: center;
  margin-top:10px;
}

.actionBox {
  margin:20px;
  padding:5px;
  text-align:left;
}

ul {
  list-style:none; 
  font-weight: bold;
  padding-left:5px;
}

a:link {
  text-decoration: none;
}

</style>