<template>
  <div class="Habilitations">
    <ul class="fail">
      <li v-for="error in errors" v-bind:key="error.id">{{ error }}</li>
    </ul>
    <ul v-if="submit" class="blue">
      <li>{{ submit }}</li>
    </ul>
    <ul>
      <li v-for="habilitations in json" v-bind:key=habilitations.id >
        <a @click="toggleForm" :id="'anchor' + habilitations.link" href="#">{{ habilitations.name }}</a>&nbsp;
        <a v-if="isDisplayed(habilitations.link)" @click="hideForm" href="#" ><span class="MinimizeIcon">&nbsp;</span></a>
          <transition name="slide-fade">
            <div :class="'retourForm ' + habilitations.link" v-if="isDisplayed(habilitations.link)" v-html="habilitations.html" @click="handleClick"></div>
          </transition>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      json: null,
      submit: null,
      clicked: null,
      errors: []
    }
  },
  mounted() {   
    this.getHab()
  },
  methods: {
    getHab: function() {
      axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/form/getHabilitations')
      .then(response => {this.json = response.data.habilitations})
    },
    serializeArray: function (form) {
      // Setup our serialized data
      var serialized = [];
      // Loop through each field in the form
        for (var i = 0; i < form.elements.length; i++) {
        var field = form.elements[i];
        // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
        if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue;
          // If a multi-select, get all selections
          if (field.type === 'select-multiple') {
            for (var n = 0; n < field.options.length; n++) {
              if (!field.options[n].selected) continue;
                serialized.push({
                  name: field.name,
                  value: field.options[n].value
                });
              }
            }
          // Convert field data to a query string
          else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
            serialized.push({
              name: field.name,
              value: field.value
            });
          }
        }
      return serialized;
    },
    toggleForm: function(e) {
      this.clicked = e.target.id.replace('anchor','')
      this.submit = null
    },
    hideForm: function() {
      this.clicked = null
      this.submit = null
      this.errors = []
    },
    isDisplayed: function(actualForm) {
      if(actualForm == this.clicked) {
        return true 
      } else {
        return false
      }
    },
    handleClick(e) {
      if (e.target.matches('.dynForm, .dynForm *')) {
        //alert('Got a click on .dynForm or a child element : ' + e.target.value + ' - ' + e.target.parentNode.parentNode.id.replace('form',''))
        if( e.target.value === 'Valider') {
          this.checkForm(event)
        }
      }
    },
    checkForm: function (e) {
      this.submit = null
      var apiUrl = "/backend/get/form/changeParam"
      this.errors = []              
      fetch(apiUrl,{
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
          },
          body: JSON.stringify( this.serializeArray(e.target.parentNode.parentNode ))
      })
      .then(res => res.json())
      .then(res => {        
        if (res.error) {
          this.errors.push(res.error)
        } else {    
          this.submit = (res.submit)
          this.getHab()
          //We have to do something but What !? :D          
        }        
      })
      e.preventDefault()    
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

ul {
  list-style:none; 
  font-weight: bold;
  padding-left:5px;
}

a:link {
  text-decoration: none;
}

.fail {
  color:red;
}

.blue {
  color: #007bff;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
h3 {
  font-size:1.05em;
  font-weight:bold;
  text-align: center;
  margin-top:10px;
}

</style>
